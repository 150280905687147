import React from 'react';
import { useNavigate } from 'react-router-dom';
import sinarmasLogo from '../assets/sinarmasLogo.svg';
import warroomIcon from '../assets/warroomIcon.svg';
import investorIcon from '../assets/investorIcon.svg';
import './Homepage.css';

const Homepage = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem('nik');
    navigate('/');
  };

  const handleClick = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div className="homepage">
      <div className="banner">
        <img src={sinarmasLogo} alt="Sinarmas Logo" />
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      <hr className="separator-line" />
      <div className="homepage-container">
        <div className="box" onClick={() => handleClick("warroom")}>
          <div className="box-title">MVD Production</div>
          <img src={warroomIcon} alt="Warroom Icon" className="box-icon" />
        </div>
        <div className="box" onClick={() => handleClick("investor")}>
          <div className="box-title">MVD Investor</div>
          <img src={investorIcon} alt="Investor Icon" className="box-icon" />
        </div>
      </div>
    </div>
  );
};

export default Homepage;