import React, { useState, useEffect, useMemo } from "react";
import {
  createStore,
  createDirectLine,
  renderWebChat,
} from "botframework-webchat";
import "./App.css";
import Eva from "./assets/eva.jpeg";

const WebChatComponent = () => {
  const store = useMemo(() => createStore(), []);

  const styleOptions = useMemo(() => ({
    backgroundColor: '#212124',
    primaryFont: 'Arial',
    avatarSize: 60,
    botAvatarBackgroundColor: 'white',
    botAvatarImage: Eva,
    botAvatarInitials: 'EV',
    showAvatarInGroup: true,

    bubbleBackground: '#303030',
    bubbleBorderColor: '#303030',
    bubbleBorderRadius: 5,

    bubbleFromUserBackground: '#28283C',
    bubbleFromUserBorderColor: '#303030',
    bubbleFromUserBorderRadius: 5,
    bubbleFromUserTextColor: 'white',
    bubbleImageHeight: '70vh',
    bubbleTextColor: 'white',

    sendBoxBorderRadius: 5,
    sendBoxBorderStyle: 'solid',
    sendBoxBackground: '#303030',
    sendBoxButtonColor: 'white',
    sendBoxPlaceholderColor: 'white',
    sendBoxTextColor: 'white',
    hideUploadButton: true,
    hideSendBox: false,

    timestampFormat: 'absolute',
    scrollToEndButtonFontSize: 8,

    typingAnimationDuration: 86400000,
    typingAnimationHeight: 20,
    typingAnimationWidth: 64,
  }), []);

  const [streamUrl, setStreamUrl] = useState(sessionStorage.getItem('streamUrl') || null);
  const [directLine, setDirectLine] = useState(null);
  const [directLineToken, setDirectLineToken] = useState(sessionStorage.getItem('directLineToken') || null);
  const NIK = useState(sessionStorage.getItem('NIK') || null);

  const fetchDirectLineToken = async () => {
    try {
      const response = await fetch(`https://mvd-api.nawatech.co/directline/token?NIK=${NIK}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const { token, conversationId, streamUrl, expires_in } = await response.json();
        sessionStorage.setItem('directLineToken', token);
        sessionStorage.setItem('conversationId', conversationId);
        sessionStorage.setItem('streamUrl', streamUrl);
        sessionStorage.setItem('expires_in', expires_in);
        setDirectLineToken(token);
        setStreamUrl(streamUrl);
      } else {
        throw new Error('Failed to fetch Direct Line token');
      }
    } catch (error) {
      console.error('Error fetching Direct Line token:', error);
    }
  };

  const refreshDirectLineToken = async () => {
    const prevToken = sessionStorage.getItem('directLineToken');
  
    fetch(`https://mvd-api.nawatech.co/directline/refresh-token?prevToken=${prevToken}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to refresh Direct Line token');
        }
      })
      .then(data => {
        const { token, expires_in } = data;
        sessionStorage.setItem('directLineToken', token);
        setDirectLineToken(token);
        sessionStorage.setItem('expires_in', expires_in);
      })
      .catch(error => {
        console.error('Error refreshing Direct Line token:', error);
      });
  }

  useEffect(() => {
    if (!streamUrl) {
      fetchDirectLineToken();
    }
  }, [streamUrl]);

  useEffect(() => {
    const initializeChat = async () => {
      if (directLineToken && streamUrl) {
        const directLine = createDirectLine({
          token: directLineToken,
          activityTimeout: 86400000,
          webSocket: new WebSocket(streamUrl),
        });

        directLine.connectionStatus$
        .subscribe(connectionStatus => {
          if (connectionStatus === 4) {
            fetchDirectLineToken();
          }
        });

        setDirectLine(directLine);
      }
    };

    initializeChat();
  }, [directLineToken, streamUrl]);

  useEffect(() => {
    if (directLine) {
      const webChatContainer = document.getElementById('webchat');

      if (webChatContainer) {
        renderWebChat({ directLine, store, styleOptions }, webChatContainer);
      }
    }
  }, [directLine, store, styleOptions]);

  useEffect(() => {
    let refreshTimer;
  
    if (directLineToken) {
      const expiresIn = sessionStorage.getItem('expires_in');
      const bufferTime = 600; 
  
      refreshTimer = setTimeout(() => {  
        refreshDirectLineToken()
      }, (expiresIn - bufferTime) * 1000);
    }
  
    return () => clearTimeout(refreshTimer);
  }, [directLineToken]);
  
  return <div id="webchat" className="webchat-container" />;
};

export default WebChatComponent;