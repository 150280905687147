import React, { useState, useEffect, useRef } from "react";
import { models } from "powerbi-client";
import SlidingDiv from "../SlidingDiv";
import "./MVDInvestor.css";

const MVDInvestor = () => {
  const [accessToken, setAccessToken] = useState("");
  const [role, setRole] = useState("");
  const embedRef = useRef(null);

  const getNikFromSession = () => {
    return sessionStorage.getItem("nik");
  };

  const getRole = async (nik) => {
    const url = `https://mvd-api.nawatech.co/api/users/get/${nik}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'X-API-Key': 'e1a38197a2c0fa11176188725b6e206dc824203f',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setRole(data.data.role);
      } else {
        console.error('Error fetching role:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching role:', error);
    }
  };

  const getAccessToken = async () => {
    const url = "https://mvd-api.nawatech.co/powerbi/get-access-token";

    try {
      const response = await fetch(url, {
        method: 'POST',
      });

      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.access_token); 
      } else {
        console.error('Error fetching access token:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  };

  const embedPowerBIReport = async () => {
    const requestBody = JSON.stringify({
      "accessLevel": "View",
      "identities": [
        {
          "datasets": ["d3276cdb-f550-422e-a424-578430e305ae"],
          "roles": [role],
          "username": getNikFromSession(),
        },
      ],
      "datasets": [
        { "id": "d3276cdb-f550-422e-a424-578430e305ae" },
      ],
      "reports": [
        {
          "allowEdit": false,
          "id": "899c2c7b-b30a-4b63-a1eb-d9381b21eb59",
        },
      ],
      "lifetimeInMinutes": 50,
    });

    try {
      const response = await fetch('https://api.powerbi.com/v1.0/myorg/GenerateToken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: requestBody,
      });

      const data = await response.json();

      if (data.token && embedRef.current) {
        const embedConfig = {
          type: 'report',
          tokenType: models.TokenType.Embed,
          accessToken: data.token,
          embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=899c2c7b-b30a-4b63-a1eb-d9381b21eb59',
          id: '899c2c7b-b30a-4b63-a1eb-d9381b21eb59',
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: false,
          },
        };

        window.powerbi.embed(embedRef.current, embedConfig);
      }
    } catch (error) {
      console.error('Error fetching embed token:', error);
    }
  };

  useEffect(() => {
    const nik = getNikFromSession();
    if (nik) {
      getRole(nik);
    }
    getAccessToken();
  }, []);

  useEffect(() => {    
    if (accessToken && role) {
      embedPowerBIReport();
    }
  }, [accessToken, role]);

  return (
    <div className="App" style={{ position: "relative" }}>
      <SlidingDiv />
      <div className="" ref={embedRef} style={{ height: '100vh', width: '100%', borderWidth:'0' }} />
    </div>
  );
};

export default MVDInvestor;
